import { navigate, RouteComponentProps } from '@reach/router';
import React from 'react';
import { toast } from 'react-toastify';
import { v4 as guid } from 'uuid';
import api from '../../lib/Api';
import Segment from '../../lib/models/Segment';
import SegmentPanel from './panels/SegmentPanel';

interface Props extends RouteComponentProps { }

interface State {
    segment: Segment,
    wait: boolean
}

export default class AddSegment extends React.Component<Props, State> {
    public constructor(props: Props) {
        super(props);

        this.state = { segment: new Segment({ id: guid() }), wait: false }
    }

    public render() {
        return <SegmentPanel
            isAdd={true}
            segment={this.state.segment}
            onHide={() => navigate('installations')}
            waiting={this.state.wait}
            onUpdate={this.onUpdate.bind(this)} />;
    }

    private onUpdate() {
        this.setState({ wait: true });

        api.segments.add(this.state.segment).then(() => navigate(`/installations/segment/${this.state.segment.id}`), (reason) => toast('Something went wrong... Verify your internet connection and try again.', { type: 'error' }));
    }
}