import React from 'react';
import ModelInput from '../../../lib/ModelInput';
import ConfigurationSet from '../../../lib/models/ConfigurationSet';

interface Props {
    isAdd: boolean;
    configurationSet: ConfigurationSet;
    onHide?: () => void;
    onUpdate?: (configurationSet: ConfigurationSet) => void;
}

interface State { }

export default class ConfigurationSetPanel extends React.Component<Props, State> {
    public render() {
        return <div className="dialog">
            <div className='block'>
                <ModelInput label="Name" src={this.props.configurationSet} field='name' />
            </div>
            <div className='buttons is-right'>
                <button className='button' onClick={() => this.props.onHide?.call(this)}>Cancel</button>
                <button className='button is-primary' onClick={() => this.props.onUpdate?.call(this, this.props.configurationSet)}>{this.props.isAdd ? 'Add' : 'Update' }</button>
            </div>
        </div>;
    }
}