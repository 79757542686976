import React from 'react';

export default class NavigationItem {
    public id: string = '';
    public hash: string = '';
    public label: string = '';
    public component: React.ComponentClass<{}, any> | undefined;

    public isVisible: boolean = false;
    public isDefault: boolean = false;

    public children: NavigationItem[] = [];
    public parent: NavigationItem | undefined;

    public constructor(init?: Partial<NavigationItem>) {
        Object.assign(this, init);
    }

    public addChild(child: NavigationItem): NavigationItem {
        child.parent = this;
        this.children.push(child);

        return this;
    }
}