import { RouteComponentProps } from '@reach/router';
import React from 'react';
import authentication from '../lib/AuthenticationManager';

interface Props extends RouteComponentProps { }
interface State { }

export default class Home extends React.Component<Props, State> {
    public render() {
        return <div className='section container'>
            <h1 className='title'>Causelink Manager</h1>
            <h1>Welcome, {authentication.identity?.displayName}</h1>
        </div>;
    }
}