import { DiagnosticDateType } from './DiagnosticDate';
import moment from 'moment';

type Listener = () => void;

export default class CurrentSession {
    private static listeners: Listener[] = [];
    private static _dateType: DiagnosticDateType;

    public static exportTimezone: number;

    public static listen(listener: Listener) {
        this.listeners.push(listener);
    }

    public static get dateType(): DiagnosticDateType {
        return this._dateType;
    }

    public static set dateType(value: DiagnosticDateType) {
        this._dateType = value;
        this.listeners.forEach((listener) => listener());
    }

    public static get offset(): number {
        if (this.dateType === DiagnosticDateType.UTC) {
            return 0;
        }

        if (this.dateType === DiagnosticDateType.TheirTZ) {
            return this.exportTimezone;
        }

        if (this.dateType === DiagnosticDateType.MyTZ) {
            return moment(moment.now()).zone();
        }

        return 0;
    }
}
