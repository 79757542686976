import React from 'react';
import ReactJson from "react-json-view";
import Select from 'react-select';
import DiagnosticSchema, { SessionDiagnosticEntry, DiagnosticLevel, DiagnosticEventType, SessionDiagnostic } from '../../lib/DiagnosticSchema';
import './SessionPanel.scss'
import DiagnosticDate from '../../lib/DiagnosticDate';
import moment from 'moment';
import { RouteComponentProps } from '@reach/router';
import { theme } from '../../../../lib/causelinkSelectTheme';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

const diagnosticLevelOptions: ReactSelectOption<DiagnosticLevel>[] = [
    { value: DiagnosticLevel.Trace, label: 'Trace' },
    { value: DiagnosticLevel.Debug, label: 'Debug' },
    { value: DiagnosticLevel.Info, label: 'Info' },
    { value: DiagnosticLevel.Warn, label: 'Warn' },
    { value: DiagnosticLevel.Error, label: 'Error' }
];

class Filter {
    public levels: DiagnosticLevel[] | undefined;
    public sections: string[] | undefined;
    public keyword: string | undefined;

    public entries(diagnostics: SessionDiagnostic): SessionDiagnosticEntry[] {
        if (this.levels !== undefined) {
            diagnostics = diagnostics.filter((entry) => this.levels?.includes(entry.type));
        }

        if (this.sections !== undefined) {
            diagnostics = diagnostics.filter((entry) => this.sections?.includes(entry.section));
        }

        if (this.keyword !== undefined) {
            const keyword = this.keyword!.toLowerCase();
            diagnostics = diagnostics.filter((entry) => JSON.stringify(entry).toLowerCase().includes(keyword));
        }

        return diagnostics;
    }

    public clone(): Filter {
        const us = new Filter();
        us.levels = this.levels;
        us.sections = this.sections;
        us.keyword = this.keyword;
        return us;
    }
}

interface ReactSelectOption<T> {
    value: T;
    label: string;
}

interface Props extends RouteComponentProps {
    diagnostics: DiagnosticSchema;
}

interface State {
    viewEntry: boolean;
    entry: SessionDiagnosticEntry | undefined;
    filter: Filter;
    filterKeyword: string;
}

export default class SessionPanel extends React.Component<Props, State> {
    private sections: ReactSelectOption<string>[] = [];

    public constructor(props: Props) {
        super(props);

        const seen: any = {};
        props.diagnostics.data.session.forEach((entry) => {
            if (!(entry.section in seen)) {
                this.sections.push({ value: entry.section, label: entry.section });
                seen[entry.section] = true;
            }
        });

        this.state = { entry: undefined, viewEntry: false, filter: new Filter(), filterKeyword: '' };
    }

    public render() {
        return (
            <div id="SessionPanel" className='container'>
                <div className="filter p-4 section block">
                    <div className='container'>
                        <div className='field'>
                            <label className='label is-small'>Level</label>
                            <Select
                                isMulti
                                className='is-small'
                                theme={theme}
                                classNamePrefix="reactSelect"
                                options={diagnosticLevelOptions}
                                defaultValue={this.state.filter.levels}
                                onChange={(items: any) => {
                                    const values = (items || []).map((i: any) => i.value);
                                    this.onFilterChange({ levels: (values.length > 0) ? values : undefined });
                                }} />
                        </div>

                        <div className='block'>
                            <label className='label is-small'>Section</label>
                            <Select
                                isMulti
                                theme={theme}
                                className='is-small'
                                classNamePrefix="reactSelect"
                                options={this.sections}
                                defaultValue={this.state.filter.sections}
                                onChange={(items: any) => {
                                    const values = (items || []).map((i: any) => i.value);
                                    this.onFilterChange({ sections: (values.length > 0) ? values : undefined });
                                }} />
                        </div>

                        <label className='label is-small'>Keyword</label>
                        <div className='field has-addons'>
                            <div className='control'>
                                <button className='button is-small' onClick={() => { this.onFilterChange({ keyword: '' }); this.setState({ filterKeyword: '' }) }}><FontAwesomeIcon icon={faTimes} /></button>
                            </div>
                            <div className='control is-flex-grow-1'>
                                <input className='input is-small' value={this.state.filterKeyword} onChange={(ev) => { this.setState({ filterKeyword: ev.target.value }); this.onFilterChange({ keyword: ev.target.value }) }} />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="list">
                    <div className='entries is-half'>
                        {this.state.filter.entries(this.props.diagnostics.data.session).map((entry, index) => this.renderEntry(entry, index))}
                    </div>
                    <div className='details p-4'>
                        {(this.state.entry !== undefined)
                            ? this.renderViewer()
                            : <p>Select an entry</p>}
                    </div>
                </div>

            </div>
        );
    }

    private renderEntry(entry: SessionDiagnosticEntry, index: number) {
        return (
            <div key={index} onClick={() => this.setState({ entry })} className={this.getClassName(entry)}>
                {entry.short}
            </div>
        );
    }

    private renderViewer() {
        const view = this.state.viewEntry ? this.state.entry! : this.state.entry!.detail;

        return (
            <div className="fixed is-size-6">
                <div className='block'>
                    <div className='title is-4'>
                        {this.state.entry?.section.toString()}</div>
                    <div className='subtitle is-6'><DiagnosticDate date={moment(this.state.entry!.at)} /></div>
                </div>

                {view
                    ? <ReactJson src={view} />
                    : ''}

                <div>
                    <button onClick={() => this.setState({ viewEntry: !this.state.viewEntry })}>{this.state.viewEntry ? 'View Details' : 'View Full Entry'}</button>
                </div>
            </div>
        );
    }

    private getClassName(entry: SessionDiagnosticEntry): string {
        let name = `entry ${entry.section} `;

        switch (entry.type) {
            case DiagnosticLevel.Trace: name += 'trace'; break;
            case DiagnosticLevel.Debug: name += 'debug'; break;
            case DiagnosticLevel.Info: name += 'info'; break;
            case DiagnosticLevel.Warn: name += 'warn'; break;
            case DiagnosticLevel.Error: name += 'error'; break;
        }

        if (entry === this.state.entry) {
            name += ' active';
        }

        return name;
    }

    private onFilterChange(val: Partial<Filter>) {
        const clone = this.state.filter.clone();
        Object.assign(clone, val);

        this.setState({ filter: clone });
    }
}