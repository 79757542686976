import React from 'react';
import IModelChanged from './IModelChanged';
import { v4 as uuidv4 } from 'uuid';

interface Props {
    label: string;
    field: string;
    options: Map<string, string>;
    src: any;
    isReadOnly?: boolean;
    onUpdate?(change: IModelChanged<string>): void;
}

interface State {
    id: string;
    model: string;
}

export class SelectInput extends React.PureComponent<Props, State> {
    private get srcValue(): any {
        return this.props.src[this.props.field];
    }

    public static defaultProps = {
        isReadOnly: false
    };

    public constructor(props: Props) {
        super(props);

        this.state = { id: uuidv4(), model: this.srcValue || '' };

        const k = Array.from(this.props.options.keys());
        if (k.length > 0) {
            this.update(k[0]);
        }
    }

    public render() {
        return (
            <div className='field'>
                <label className='label' htmlFor={this.state.id}>{this.props.label}</label>
                <div className='select'>
                    <select id={this.state.id} onChange={(ev) => this.update(ev.target.value)} value={this.state.model} disabled={this.props.isReadOnly}>
                        {Array.from(this.props.options.keys()).map((k) =>
                            <option key={k} value={k}>{this.props.options.get(k)}</option>
                        )}
                    </select>
                </div>
            </div>
        );
    }

    protected update(val: string) {
        this.setState({ model: val });

        if (this.props.onUpdate) {
            this.props.onUpdate!({
                field: this.props.field,
                prev: this.srcValue,
                current: val
            });
        }
    }
}
