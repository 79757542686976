import React from 'react';
import Segment from '../../../lib/models/Segment';
import { regions, environments } from '../../../lib/Known';
import ModelInput from '../../../lib/ModelInput';
import { SelectInput } from '../../../lib/SelectInput';

interface Props {
    isAdd: boolean;
    segment: Segment;
    waiting?: boolean;

    onHide?: () => void;
    onUpdate?: (segment: Segment) => void;
}

interface State { }
export default class SegmentPanel extends React.Component<Props, State> {
    public constructor(props: Props) {
        super(props);
        (this.props.segment as any).connectionStringUnencrypted = '';
    }
    public render() {
        const submitIsDisabled = !this.props.segment.name;

        const primaryButtonClasses = ['button', 'is-primary'];

        if (this.props.waiting)
            primaryButtonClasses.push('is-loading');

        return <div className='section container dialog'>
            {this.props.isAdd ?
                <h2 className='title'>Add Segment</h2>
                :
                <h2 className='subtitle'>Edit Segment</h2>
            }
            <div className='block'>
                <ModelInput label="Name" src={this.props.segment} field='name' onUpdate={() => this.forceUpdate()} />
                <ModelInput label="Hosted Zone" src={this.props.segment} field='hostedZone' onUpdate={() => this.forceUpdate()}/>
                <ModelInput label="CFACMARN" src={this.props.segment} field='cfacmarn' onUpdate={() => this.forceUpdate()}/>
                <ModelInput label="CFACMARN" src={this.props.segment} field='cfacmarn' onUpdate={() => this.forceUpdate()}/>
                <ModelInput label="Domain" src={this.props.segment} field='domain' onUpdate={() => this.forceUpdate()}/>
                <ModelInput label="ElasticSearch" src={this.props.segment} field='elasticSearch' onUpdate={() => this.forceUpdate()}/>
                <SelectInput label="Region" src={this.props.segment} field='region' options={regions} onUpdate={(mc) => this.props.segment.region = mc.current} isReadOnly={!this.props.isAdd} />
                <SelectInput label="Environment" src={this.props.segment} field='environment' options={environments} isReadOnly={!this.props.isAdd} onUpdate={(mc) => this.props.segment.environment = mc.current} />
                <ModelInput label="Default Version" src={this.props.segment} field='defaultVersion' onUpdate={() => this.forceUpdate()}/>
                <ModelInput label="Balancer Arn" src={this.props.segment} field='balancerArn' onUpdate={() => this.forceUpdate()}/>
                <ModelInput label="AutoScaling Group Name" src={this.props.segment} field='autoScalingGroup' onUpdate={() => this.forceUpdate()}/>
                <ModelInput label="SQS URL" src={this.props.segment} field='sqsurl' onUpdate={() => this.forceUpdate()}/>
                <ModelInput label="Connection String" src={this.props.segment} field='connectionStringUnencrypted' onUpdate={() => this.forceUpdate()}/>
            </div>
            <div className='buttons is-right'>
                <button className='button' onClick={() => this.props.onHide?.call(this)}>Cancel</button>
                <button disabled={submitIsDisabled} className={primaryButtonClasses.join(' ')} onClick={() => this.props.onUpdate?.call(this, this.props.segment)}>{this.props.isAdd ? 'Add' : 'Update'}</button>
            </div>
        </div>;
    }
}