import React from "react";
import moment from 'moment';
import CurrentSession from "./CurrentSession";

export enum DiagnosticDateType {
    UTC, TheirTZ, MyTZ
}

interface Props {
    date: moment.Moment;
}

interface State {
    offset: number;
}

export default class DiagnosticDate extends React.Component<Props, State> {
    public constructor(props: Props) {
        super(props);

        this.state = { offset: 0 };
        this.onOffsetChanged();
        CurrentSession.listen(this.onOffsetChanged.bind(this));
    }

    public render() {
        return (
            <div className="DiagnosticDate">
                {this.props.date.utcOffset(this.state.offset).toString()}
            </div>
        );
    }

    private onOffsetChanged() {
        this.setState({ offset: CurrentSession.offset });
    }
}