import * as React from 'react';
import DiagnosticSchema, { SessionDiagnosticEntry, DiagnosticEventType, DiagnosticLevel } from '../../lib/DiagnosticSchema';
import './StoryPanel.scss';
import moment from 'moment';
import { RouteComponentProps } from '@reach/router';

export interface Props extends RouteComponentProps {
    diagnostics: DiagnosticSchema;
}

export interface State {

}

export default class StoryPanel extends React.Component<Props, State> {
    private lastAction: SessionDiagnosticEntry | undefined;

    public constructor(props: Props) {
        super(props);
        this.state = { };
    }

    public render() {
        return (
            <div id="StoryPanel">
                {this.props.diagnostics.data.session.map((entry, index) => this.renderEntry(entry, index))}
            </div>
        );
    }

    private renderEntry(entry: SessionDiagnosticEntry, index: number) {
        if (entry.section === 'clicked') {
            return this.renderClickedEntry(entry, index);
        }

        if (entry.section.toLowerCase() === 'navigate') {
            return this.renderNavigateEntry(entry, index);
        }

        if (entry.type >= DiagnosticLevel.Warn) {
            return this.renderWarnError(entry, index);
        }

        if ([ 'Undo', 'Redo' ].includes(entry.section)) {
            return this.renderUndoRedoEntry(entry, index);
        }

        return '';
    }

    private renderClickedEntry(entry: SessionDiagnosticEntry, index: number) {
        return (
            <div key={index} className="clicked">
                <div>{entry.short}</div>
                {this.renderOffset(entry)}
            </div>
        );
    }

    private renderNavigateEntry(entry: SessionDiagnosticEntry, index: number) {
        return (
            <div key={index} className="navigate">
                {entry.detail.fromState.url} ={'>'} {entry.detail.toState.url}
            </div>
        );
    }

    private renderUndoRedoEntry(entry: SessionDiagnosticEntry, index: number) {
        return (
            <div key={index} className={entry.section}>
                <div>{entry.short}</div>
                {this.renderOffset(entry)}
            </div>
        );
    }

    private renderWarnError(entry: SessionDiagnosticEntry, index: number) {
        return (
            <div key={index} className="warnerror">
                {entry.short}
            </div>
        );
    }

    private renderOffset(entry: SessionDiagnosticEntry) {
        let seconds = 0;
        if (this.lastAction !== undefined) {
            seconds = moment(entry.at).diff(this.lastAction!.at, 'seconds', true);
        }

        this.lastAction = entry;

        const minutes = Math.floor(seconds / 60);
        const sRemainder = seconds - (minutes * 60);

        let format = '+';

        if (minutes > 0) {
            format += `${minutes}m `;
        }

        format += `${sRemainder.toFixed(2)}s`;

        return (
            <div className="offset">{format}</div>
        )
    }
}
