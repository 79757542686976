import Known from '../Known';

export default class ApplicationUser {
    public id: string = '';
    public roleId: string = '';
    public userId: string = '';
    public displayName: string = '';

    public get roleName(): string {
        switch (this.roleId) {
            case Known.Roles.AdminId: return 'Admin';
            case Known.Roles.AnalystId: return 'Analyst';
            case Known.Roles.ITId: return 'IT';
            case Known.Roles.ManagerId: return 'Manager';
            case Known.Roles.ParticipantId: return 'Participant';                        
        }

        return '';
    }

    public constructor(init?: Partial<ApplicationUser>) {
        Object.assign(this, init);
    }
}