const SessionDiagnostics: string = 'Session Diagnostics';
const PersistentDiagnostics: string = 'Persistent Diagnostics';

// #region Schema Version 1 (Causelink >= 3?) && (Causelink < 6)
enum DiagnosticLevel_v1 {
    Trace, Debug, Info, Warn, Error
}

interface SessionDiagnosticEntry_v1 {
    type: DiagnosticLevel_v1;
    location: string;
    short: string;
    detail: any;
    at: Date;
}

class DiagnosticSchema_v1 extends Array<SessionDiagnosticEntry_v1> { }
// #endregion

// #region Schema Version 2
type DiagnosticLevel_v2 = DiagnosticLevel_v1;
const DiagnosticLevel_v2 = {...DiagnosticLevel_v1};

enum DiagnosticEventType_v2 {
    Error = 1 << 1,
    Startup = 1 << 2,
    Http = 1 << 3,
    HttpRequest = 1 << 4,
    HttpResponse = 1 << 5,
    Click = 1 << 6,
    Navigate = 1 << 7,

    HttpError = Error | Http
}

interface SessionDiagnosticEntry_v2 {
    type: DiagnosticLevel_v2;
    section: string;
    short: string;
    detail: any;
    at: Date;
}

class PersistentDiagnosticEntry_v2 {
    public type: DiagnosticEventType_v2 = DiagnosticEventType_v2.Error;
    public source: string = '';
    public target: any;
    public details: any;
    public at: Date = new Date(1970, 0, 0);

    public constructor(init?: Partial<PersistentDiagnosic_v2>) {
        Object.assign(this, init);
    }

    public is(flag: DiagnosticEventType_v2): boolean {
        return (flag === (this.type & flag));
    }
}

interface SessionDiagnostic_v2 extends Array<SessionDiagnosticEntry_v2> { }
interface PersistentDiagnosic_v2 extends Array<PersistentDiagnosticEntry_v2> { }

interface DataHandlers_v2 {
    session: SessionDiagnostic_v2;
    persistent: PersistentDiagnosic_v2;
}

interface DiagnosticSchemaTimeInfo_v2 {
    time: Date;
    timezone: string;
    offset: number;
}

class DiagnosticSchema_v2 {
    public at: DiagnosticSchemaTimeInfo_v2 | undefined;
    public url: any;
    public meta: any;
    public handlers: string[] = [];
    public data: DataHandlers_v2 = { session: [], persistent: [] };
    public userAgent: string | undefined;
    public schemaVersion: number = 2;

    public static from(json: any): DiagnosticSchema_v2 {
        if ((json.schemaVersion === undefined) || (parseInt(json.schemaVersion) < 2)) {
            // we can assume this object is a pre-v6 diagnostics file
            return this.fromSchemaV1(json as DiagnosticSchema_v1);
        }

        const us = new DiagnosticSchema_v2(json);
        us.at!.time = new Date(us.at!.time);

        if (SessionDiagnostics in json.handlers) {
            us.data.session = json.handlers[SessionDiagnostics];
            us.data.session.forEach((entry) => entry.at = new Date(entry.at));
        }

        if (PersistentDiagnostics in json.handlers) {
            us.data.persistent = json.handlers[PersistentDiagnostics].map((entry: any) => {
                entry.at = new Date(entry.at);
                return new PersistentDiagnosticEntry_v2(entry);
            });
        }

        us.handlers = Object.keys(us.handlers);
        return us;
    }

    private static fromSchemaV1(preV6: DiagnosticSchema_v1): DiagnosticSchema_v2 {
        const us = new DiagnosticSchema_v2();
        const firstEntry = preV6[0];

        us.at = { time: new Date(firstEntry.at), timezone: 'Unknown Timezone', offset: 0 };
        us.url = 'Unknown';
        us.handlers = [ SessionDiagnostics ];

        // convert SessionDiagnosticEntry_v1 to SessionDiagnosticEntry_v2 entries
        us.data.session = preV6.map((entry: SessionDiagnosticEntry_v1) => {
            // < v6 screwed up one of these entries
            if ((entry.type === DiagnosticLevel_v1.Info) && entry.location.startsWith('Server version')) {
                us.meta = entry.short;
                entry.short = entry.location;
            }

            return {
                type: entry.type,
                section: entry.location,
                short: entry.short,
                detail: entry.detail,
                at: new Date(entry.at)
            };
        });

        us.userAgent = firstEntry.short;
        us.schemaVersion = 1;

        return us;
    }

    public get hasSessionData(): boolean {
        return (this.handlers.includes(SessionDiagnostics) && (this.data.session.length > 0));
    }

    public get hasPersistentData(): boolean {
        return (this.handlers.includes(PersistentDiagnostics) && (this.data.persistent.length > 0));
    }

    public constructor(init?: Partial<DiagnosticSchema_v2>) {
        Object.assign(this, init);
    }
}
// #endregion

export type DiagnosticEventType = DiagnosticEventType_v2;
export const DiagnosticEventType = {...DiagnosticEventType_v2};
export type DiagnosticLevel = DiagnosticLevel_v2;
export const DiagnosticLevel = {...DiagnosticLevel_v2};

export interface SessionDiagnosticEntry extends SessionDiagnosticEntry_v2 { }
export interface PersistentDiagnosticEntry extends PersistentDiagnosticEntry_v2 { }
export interface SessionDiagnostic extends SessionDiagnostic_v2 { }
export interface PersistentDiagnosic extends PersistentDiagnosic_v2 { }

export default class DiagnosticSchema extends DiagnosticSchema_v2 { }