import React, { InputHTMLAttributes } from 'react';
import TextareaAutosize from 'react-autosize-textarea/lib';
import { v4 as uuidv4 } from 'uuid';

interface Props extends InputHTMLAttributes<HTMLElement> {
    label: string;
    isReadOnly?: boolean;
}

interface State {
    value: Props['value'];
    focused: boolean;
}

export default class Input extends React.PureComponent<Props, State> {
    public static defaultProps = {
        isReadOnly: false
    };

    private id = uuidv4();
    private inputElem: React.RefObject<HTMLInputElement & HTMLTextAreaElement>;

    public constructor(props: Props) {
        super(props);

        this.state = { value: this.props.value, focused: false };

        this.inputElem = React.createRef();
    }

    public componentDidMount() {
        // Event listeners to prevent focus from being lost when changing tabs
        this.inputElem.current?.addEventListener('focusin', this.onFocusin);
        this.inputElem.current?.addEventListener('focusout', this.onFocusout);
    }

    public componentWillUnmount() {
        this.inputElem.current?.removeEventListener('focusin', this.onFocusin);
        this.inputElem.current?.removeEventListener('focusout', this.onFocusout);

    }

    public render() {
        if (this.props.type === 'integer') {
            return this.renderIntegerInput();
        } else if (this.props.type === 'number') {
            return this.renderNumberInput();
        } else {
            return this.renderTextInput();
        }
    }

    protected update(val: string) {
        this.setState({ value: val });
    }

    private renderNumberInput() {
        const { label, onChange, type, ...props } = this.props;
        return <div className='field'>
            <label className='label' htmlFor={this.id}>{label}</label>
            <input
                {...props}
                id={this.id}
                className={`input ${(this.state.focused || this.state.value !== '') ? 'active' : ''}`}
                ref={this.inputElem}
                type='number'
                onKeyDown={(e) => {
                    // e cannot be in the regex pattern unless "Backspace" is accounted for
                    if (e.key.match(/\-|\+/g) || e.key === 'e') {
                        e.preventDefault();
                    }
                }
                }
                disabled={this.props.isReadOnly}
                value={this.state.value}
                onChange={(ev) => {
                    this.update(ev.target.value);
                    if (onChange) {
                        onChange(ev);
                    }
                }} />
        </div>;
    }

    private renderIntegerInput() {
        const { label, onChange, type, ...props } = this.props;
        return <div className='field'>
            <label className='label' htmlFor={this.id}>{label}</label>
            <input
                {...props}
                id={this.id}
                className={`label${(this.state.focused || this.state.value !== '') ? ' active' : ''}`}
                ref={this.inputElem}
                type='number'
                onKeyDown={(e) => {
                    // e cannot be in the regex pattern unless "Backspace" is accounted for
                    if (e.key.match(/\-|\+|\./g) || e.key === 'e') {
                        e.preventDefault();
                    }
                }
                }
                disabled={this.props.isReadOnly}
                value={this.state.value}
                onChange={(ev) => {
                    this.update(ev.target.value);
                    if (onChange) {
                        onChange(ev);
                    }
                }} />
        </div>;
    }

    private renderTextInput() {
        const { label, onChange, ...props } = this.props;
        return <div className='field'>
            <label className='label' htmlFor={this.id}>{label}</label>
            <input
                {...props}
                onFocus={(ev) => ev.target.selectionStart = ev.target.selectionEnd = ev.target.value.length}
                id={this.id}
                className={`input ${(this.state.focused || this.state.value !== '') ? 'active' : ''}`}
                type={this.props.type?.toString()}
                disabled={this.props.isReadOnly}
                value={this.state.value}
                ref={this.inputElem}
                onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
                    this.update(ev.target.value);
                    if (onChange) {
                        onChange(ev);
                    }
                }} />
        </div>;
    }

    private onFocusin = () => {
        this.setState({ focused: true });
    }

    private onFocusout = () => {
        if (document.activeElement !== this.inputElem.current) {
            this.setState({ focused: false });
        }
    }

}
