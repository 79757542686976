import React from 'react';
import api from '../../../lib/Api';
import Installation from '../../../lib/models/Installation';
import Segment from '../../../lib/models/Segment';
import InstallationPanel from '../panels/InstallationPanel';
import ConfirmPanel from '../panels/ConfirmPanel';
import WaitPanel from '../panels/WaitPanel';
import { navigate, RouteComponentProps } from '@reach/router';

interface Props extends RouteComponentProps {
    id?: string;
    segmentId?: string;
}

interface State {
    segment: Segment | undefined;
    installation: Installation | undefined;
    connectionString: string;
    confirm: boolean;
    wait: boolean;
}

export default class EditInstance extends React.Component<Props, State> {
    public constructor(props: Props) {
        super(props);

        this.state = {
            segment: undefined,
            installation: undefined,
            connectionString: '',
            confirm: false,
            wait: false
        };

        api.segments.find(this.props.segmentId!).then((segment) => this.setState({ segment }));
        api.installations.find(this.props.id!).then((installation) => this.setState({ installation }));
    }

    public render() {
        return <div className='section container'>
            <h3>Installations &gt; {this.state.segment?.name} &gt; Edit {this.state.installation?.name}</h3>

            {this.renderStates()}
        </div>
    }

    private renderStates() {
        if ((this.state.wait) || (this.state.segment === undefined) || (this.state.installation === undefined)) {
            return <WaitPanel />;
        }

        if (this.state.confirm) {
            return <ConfirmPanel action="update instance" onCancel={() => this.setState({ confirm: false })} onConfirm={this.onUpdate} />;
        }

        return <InstallationPanel isAdd={false} onHide={this.onCancel.bind(this)} onUpdate={(installation) => this.setState({ confirm: true, installation })} installation={this.state.installation} segment={this.state.segment} />
    }

    private onCancel() {
        // redirectTo('installation.segment', new Map([['id', this.state.segment?.id!]]));
        navigate(-1);
    }

    private onUpdate = () => {
        const o: any = { id: this.state.installation?.id };
        if (this.state.installation!.connectionString) {
            o.connectionStringUnencrypted = this.state.installation!.connectionString;
        }

        api.installations.update(o).then(() => {
            navigate(-1);
        });
    }
}