import { RouteComponentProps } from '@reach/router';
import React from 'react';

interface Props extends RouteComponentProps { }
interface State { }

export default class Denied extends React.Component<Props, State> {
    public render() {
        return (
            <div>
                <h1>Access Denied</h1>

                <p>Your account is not allowed to perform the action.</p>
            </div>)
    }
}