import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import api from '../../../lib/Api';
import Installation from '../../../lib/models/Installation';
import Segment from '../../../lib/models/Segment';
import InstallationPanel from '../panels/InstallationPanel';
import ConfirmPanel from '../panels/ConfirmPanel';
import { RemoteCommand } from '../../../lib/models/RemoteCommand';
import { navigate, RouteComponentProps } from '@reach/router';
import { toast } from 'react-toastify';

interface Props extends RouteComponentProps {
    id?: string;
}

interface State {
    segment: Segment | undefined;
    installation: Installation;
    commands?: RemoteCommand[];
    confirm: boolean;
    wait: boolean;
}

export default class AddInstance extends React.Component<Props, State> {
    public constructor(props: Props) {
        super(props);

        this.state = {
            segment: undefined,
            installation: new Installation({ id: uuidv4(), segmentId: this.props.id }),
            commands: [],
            confirm: false,
            wait: false
        };

        api.segments.find(this.props.id!).then((segment) => this.setState({ segment }));
    }

    public render() {
        return <div className='section container'>
            <h3>Installations &gt; {this.state.segment?.name} &gt; Add Instance</h3>

            {this.renderStates()}
        </div>
    }

    private renderStates() {
        if (!this.state.segment) return;

        if (this.state.confirm) {
            return <ConfirmPanel action="add instance" onCancel={() => this.setState({ confirm: false })} onConfirm={this.onAdd} />;
        }

        return <InstallationPanel isAdd={true} onHide={this.onCancel.bind(this)} onUpdate={(installation, commands) => this.setState({ confirm: true, commands, installation })} installation={this.state.installation} segment={this.state.segment!} />;
    }

    private onCancel() {
        navigate(-1);
    }

    private onAdd = async () => {
        api.segments.addInstallation(this.state.installation).then(
            () => {
                const resolve = () => { return navigate(`/installations/segment/${this.state.segment!.id}`) };

                if ((this.state.commands !== undefined) && (this.state.commands?.length > 0)) {
                    const messageGroupId = uuidv4().toString();
                    const a: (() => Promise<any>)[] = (this.state.commands.map((command) => {
                        command.messageGroupId = messageGroupId;
                        return api.commands.process.bind(this, command);
                    }));

                    a.push(resolve);
                    let chain: Promise<any> = Promise.resolve();
                    a.forEach(command => {
                        chain = chain.then(command);
                    });
                } else {
                    resolve();
                }
            },
            (error) => {
                error.json().then((response: any) => {
                    if (response.title === 'Conflict')
                        toast('Name/DNS already in use', { type: 'error', autoClose: false, });
                });
            });
    }
}