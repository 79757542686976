import React, { useEffect, useState } from 'react';
import './WaitPanel.scss';

const WaitPanel: React.FC = () => {
    const [fadeIn, setFadeIn] = useState(false);

    useEffect(() => setFadeIn(true), []);

    return <div id='wait-container' className={fadeIn ? 'fade-in' : ''}><div id='wait-overlay'></div><div id="wait-panel"></div></div>;
};

export default WaitPanel;