import { RouteComponentProps } from '@reach/router';
import React from 'react';

interface Props extends RouteComponentProps { }
interface State { }

export default class Error extends React.Component<Props, State> {
    public render() {
        return <div className='section container'>
            <h1 className='title'>Error</h1>
            <h1>An Unknown / Unhandled Error has Occured</h1>
            <p>Please try your request again.</p>
        </div>;
    }
}