import { v4 } from "uuid";
import ConfigurationSet from "./ConfigurationSet";

/*
 * this needs to match RemoteCommandType.cs in API
 */
export enum RemoteCommandType {
    AddCauselinkInstance,
    RemoveCauselinkInstance,
    InstallDatabase,
    UpdateDatabase,
    RemoveDatabase,
    RebuildIndex,
    UpdateConfiguration,
    Restart,
    Migrate,
    AddToUptimeRobot,
    RemoveFromUptimeRobot
}

export function describeCommandType(type: RemoteCommandType, configurationSets?: ConfigurationSet[]) {
    switch (type) {
        case RemoteCommandType.AddCauselinkInstance: return 'Add Causelink Instance (invoke lambda)';
        case RemoteCommandType.RemoveCauselinkInstance: return 'Remove Causelink Instance (invoke lambda)';
        case RemoteCommandType.InstallDatabase: return 'Install Seed Database';
        case RemoteCommandType.UpdateDatabase: return 'Update Database';
        case RemoteCommandType.RemoveDatabase: return 'Delete Database';
        case RemoteCommandType.RebuildIndex: return 'Rebuild Index';
        case RemoteCommandType.UpdateConfiguration: return 'Update Configuration';
        case RemoteCommandType.Restart: return 'Restart';
        case RemoteCommandType.Migrate: return 'Migrate';
        case RemoteCommandType.AddToUptimeRobot: return 'Add to Uptime Robot';
        case RemoteCommandType.RemoveFromUptimeRobot: return 'Remove from Uptime Robot';
    }

    return '{unknown command}';
}

export abstract class RemoteCommand
{
    public id: string = v4();
    public messageGroupId: string = 'causelink.manager';
    public type: RemoteCommandType = RemoteCommandType.AddCauselinkInstance;
    public component: string | null | undefined;

    public constructor(public installationId: string) { }

    public toString(): string {
        return describeCommandType(this.type);
    }
}

export class AddCauselinkInstanceCommand extends RemoteCommand {
    public constructor(installationId: string) {
        super(installationId);
        this.type = RemoteCommandType.AddCauselinkInstance;
    }
}

export class RemoveCauselinkInstanceCommand extends RemoteCommand {
    public constructor(installationId: string) {
        super(installationId);
        this.type = RemoteCommandType.RemoveCauselinkInstance;
    }
}

export class InstallDatabaseCommand extends RemoteCommand {
    public constructor(installationId: string) {
        super(installationId);
        this.type = RemoteCommandType.InstallDatabase;
    }
}

export class UpdateDatabaseCommand extends RemoteCommand {
    public constructor(installationId: string) {
        super(installationId);
        this.type = RemoteCommandType.UpdateDatabase;
    }
}

export class RemoveDatabaseCommand extends RemoteCommand {
    public constructor(installationId: string) {
        super(installationId);
        this.type = RemoteCommandType.RemoveDatabase;
    }
}

export class RebuildIndexCommand extends RemoteCommand {
    public constructor(installationId: string) {
        super(installationId);
        this.type = RemoteCommandType.RebuildIndex;
    }
}

export class UpdateConfigurationCommand extends RemoteCommand {
    public configurationSetIds: string[] = [];

    public constructor(installationId: string) {
        super(installationId);
        this.type = RemoteCommandType.UpdateConfiguration;
    }
}

export class RestartCommand extends RemoteCommand {
    public constructor(installationId: string) {
        super(installationId);
        this.type = RemoteCommandType.Restart;
    }
}

export class MigrateCommand extends RemoteCommand {
    public migrateStep: string = '';

    public constructor(installationId: string) {
        super(installationId);
        this.type = RemoteCommandType.Migrate;
    }
}

export class AddToUptimeRobot extends RemoteCommand {
    public constructor(installationId: string) {
        super(installationId);
        this.type = RemoteCommandType.AddToUptimeRobot;
    }
}

export class RemoveFromUptimeRobot extends RemoteCommand {
    public constructor(installationId: string) {
        super(installationId);
        this.type = RemoteCommandType.RemoveFromUptimeRobot;
    }
}