import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from '@reach/router';
import Segment from '../../../lib/models/Segment';
import Installation from '../../../lib/models/Installation';
import api from '../../../lib/Api';
import './CopyInstance.scss';

interface Props extends RouteComponentProps {
    id?: string;
    segmentId?: string;
}

const CopyInstance: React.FC<Props> = (props: Props) => {
    const [ segment, setSegment ] = useState<Segment>();
    const [ instance, setInstance ] = useState<Installation>();
    const [ options, setOptions ] = useState<Map<string, Segment[]>>();
    const [ targetSegment, setTargetSegment ] = useState<Segment>();
    const [ segmentMap, setSegmentMap ] = useState<Map<string, Segment>>(new Map<string, Segment>());

    useEffect(() => {
        api.installations.find(props.id!).then(setInstance);
    }, [ props.id ]);

    useEffect(() => {
        api.segments.list().then((segments) => {
            const smap = new Map<string, Segment>();
            const optionsMap = new Map<string, Segment[]>();

            segments.forEach((segment) => {
                const environment = segment.environment || 'Unknown';

                if (!optionsMap.has(environment)) {
                    optionsMap.set(environment, []);
                }

                optionsMap.get(environment)?.push(segment);

                if (segment.id === props.segmentId) {
                    setSegment(segment);
                }

                smap.set(segment.id, segment);
            })

            setOptions(optionsMap);
            setSegmentMap(smap);
        });
    }, [ props.segmentId ]);

    return (
        <div id="CopyInstance" className='section container'>
            <h3>Installations &gt; {segment?.name} &gt; Copy {instance?.name}</h3>

            <h2>Move From</h2>

            <table>
                <tbody>
                <tr>
                    <th>Environment</th>
                    <td>{segment?.environment}</td>
                </tr>
                <tr>
                    <th>Segment</th>
                    <td>{segment?.name}</td>
                </tr>
                <tr>
                    <th>Name</th>
                    <td>{instance?.name}</td>
                </tr>
                <tr>
                    <th>Version Match</th>
                    <td>{instance?.versionMatch}</td>
                </tr>
                <tr>
                    <th>Version</th>
                    <td>{instance?.version}</td>
                </tr>
                </tbody>
            </table>

            <h2>To

            {options
                ?
                    <div><select onChange={(ev) => setTargetSegment(segmentMap.get(ev.target.value))}>
                        {Array.from(options.keys()).map((environment) => {
                            return (
                                <>
                                    <option key={environment}>{environment}</option>
                                    {options.get(environment)!
                                        .filter((segment) => segment.id !== props.segmentId)
                                        .map((segment) =>
                                            <option key={segment.id} value={segment.id}>&nbsp;&nbsp;&nbsp;&nbsp;{segment.name}</option>
                                        )}
                                </>
                            )
                        })}
                    </select></div>
                : ''}

            </h2>

            {targetSegment
                ?
                    <table>
                        <tbody>
                        <tr>
                            <th>Environment</th>
                            <td>{targetSegment?.environment}</td>
                        </tr>
                        <tr>
                            <th>Segment</th>
                            <td>{targetSegment?.name}</td>
                        </tr>
                        <tr>
                            <th>Name</th>
                            <td>{instance?.name}</td>
                        </tr>
                        <tr>
                            <th>Version Match</th>
                            <td>{instance?.versionMatch}</td>
                        </tr>
                        </tbody>
                    </table>
                : ''}
        </div>
    );
};

export default CopyInstance;