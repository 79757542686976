import { RouteComponentProps } from '@reach/router';
import React from 'react';

interface Props extends RouteComponentProps { }
interface State { }

export default class Unauthenticated extends React.Component<Props, State> {
    public render() {
        return <div className='section container'>
            <h1 className='title'>Not Authenticated</h1>

            <p>The only supported login mechanism is via Azure. Visit <a href="https://www.office.com/">office.com</a>, find the manager app and click it to continue.</p>

            <p>Note that the session lifetime for this application is hardcoded at two hours.</p>

            <div className='buttons is-right'>
                <a className='button is-primary' href='https://www.office.com/'>Authenticate</a>
            </div>
        </div>
    }
}