import React from 'react';
import IModelChanged from './IModelChanged';
import Input from './Input';

interface Props {
    label: string;
    field: string;
    placeholder?: string;
    src: any;
    isReadOnly?: boolean;
    type?: string;
    debounceInterval?: number;
    onUpdate?(change: IModelChanged<string>): void;
}

interface State {
    model: string;
    focused: boolean;
}

const debounce = (wait: number, func: Function): any => {
    let timeout: number | undefined;

    return function (this: any) {
        const context = this;
        const args = arguments;
        const later = function () {
            timeout = undefined;
            func.apply(context, args);
        };

        clearTimeout(timeout);
        timeout = (setTimeout(later, wait) as any) as number;
    };
};

export default class ModelInput extends React.PureComponent<Props, State> {
    private get srcValue(): any {
        return this.props.src[this.props.field];
    }

    public static defaultProps = {
        debounceInterval: 1200,
        type: 'text',
        isReadOnly: false
    };

    private sendUpdateDebounced = debounce(this.props.debounceInterval!, this.sendUpdate.bind(this));

    public constructor(props: Props) {
        super(props);

        this.state = { model: this.srcValue || '', focused: false };
    }

    public render() {
        return (
            <Input
                type={this.props.type?.toString()}
                onChange={(ev: React.ChangeEvent<HTMLTextAreaElement>) => this.update(ev.target.value)}
                isReadOnly={this.props.isReadOnly}
                value={this.state.model || ''}
                label={this.props.label}
                placeholder={this.props.placeholder}
            />
        );
    }

    protected update(val: string) {
        this.props.src[this.props.field] = val;

        this.setState({ model: val });
        this.sendUpdateDebounced(val);
    }

    private sendUpdate(val: string) {
        if (this.props.onUpdate) {
            this.props.onUpdate!({
                field: this.props.field,
                prev: this.srcValue,
                current: val
            });
        }
    }
}
