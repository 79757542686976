export default class Segment {
    public id: string = '';
    public name: string = '';
    public domain: string = '';
    public region: string | undefined;
    public environment: string | undefined;
    public balancerArn: string | undefined;
    public autoScalingGroup: string | undefined;
    public sqsurl: string | undefined;
    public elasticSearch: string | undefined;
    public cfacmarn: string | undefined;
    public cfApiCachePolicy: string | undefined;
    public cfEdgeLambdaArn: string | undefined;
    public defaultVersion: string | undefined;
    public hostedZone: string | undefined;

    public constructor(partial?: Partial<Segment>) {
        Object.assign(this, partial);
    }
}