import React from 'react';
import ReactJson from "react-json-view";
import DiagnosticSchema from '../../lib/DiagnosticSchema';
import './MetaPanel.css'
import { UAParser } from 'ua-parser-js';
import DiagnosticDate from '../../lib/DiagnosticDate';
import moment from 'moment';
import { RouteComponentProps } from '@reach/router';

interface Props extends RouteComponentProps {
    diagnostics: DiagnosticSchema;
}

export default class MetaPanel extends React.Component<Props> {
    public render() {
        const parser = new UAParser();
        parser.setUA(this.props.diagnostics.userAgent!);

        return (
            <div className="MetaPanel section container">
                <h1 className='title'>Meta Information</h1>
                <table className='table is-striped is-bordered'>
                    <tbody>
                        <tr>
                            <td>Exported Schema</td>
                            <td>v{this.props.diagnostics.schemaVersion}</td>
                        </tr>
                        <tr>
                            <td>Date</td>
                            <td><DiagnosticDate date={moment(this.props.diagnostics.at!.time)} /></td>
                        </tr>
                        <tr>
                            <td rowSpan={2}>Log Information</td>
                            <td>
                                Session Diagnostics {this.props.diagnostics.data.session.length ?
                                    <>
                                        <span>({this.props.diagnostics.data.session.length}) </span>
                                        <span className='has-text-success'>✓</span>
                                    </>
                                    : <span className='has-text-danger'>x</span>}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Persistent Diagnostics {this.props.diagnostics.data.persistent.length ?
                                    <>
                                        <span>({this.props.diagnostics.data.persistent.length}) </span>
                                        <span className='has-text-success'>✓</span>
                                    </>
                                    : <span className='has-text-danger'>x</span>}
                            </td>
                        </tr>
                        <tr>
                            <td>Start Date</td>
                            <td><DiagnosticDate date={moment(this.props.diagnostics.data.persistent[0].at)} /></td>
                        </tr>
                        <tr>
                            <td>End Date</td>
                            <td><DiagnosticDate date={moment(this.props.diagnostics.data.persistent[this.props.diagnostics.data.persistent.length - 1].at)} /></td>
                        </tr>
                    </tbody>
                </table>
                {/* <div>
                    <h4>Exported (Schema v{this.props.diagnostics.schemaVersion})</h4>
                    <div><DiagnosticDate date={moment(this.props.diagnostics.at!.time)} /></div>
                    <div>{this.props.diagnostics.at!.timezone} ({this.props.diagnostics.at!.offset || 'UTC'})</div>
                </div>
                <div>
                    <h4>Containing Information</h4>
                    <ul>
                        {this.props.diagnostics.hasSessionData
                            ? <li>Session Diagnostics ({this.props.diagnostics.data.session.length})</li>
                            : ''}
                        {this.props.diagnostics.hasPersistentData
                            ?
                            <li>Persistent Diagnostics ({this.props.diagnostics.data.persistent.length})
                                    <ul>
                                    <li>Earliest: <DiagnosticDate date={moment(this.props.diagnostics.data.persistent[0].at)} /></li>
                                    <li>Latest: <DiagnosticDate date={moment(this.props.diagnostics.data.persistent[this.props.diagnostics.data.persistent.length - 1].at)} /></li>
                                </ul>
                            </li>
                            : ''}
                    </ul>
                </div> */}
                <div className='block p-4 has-background-white-bis'>
                    <h4 className='subtitle'>User Agent</h4>
                    <ReactJson src={parser.getResult()} collapsed={1} />
                </div>

                {(this.props.diagnostics.meta)
                    ?
                    <div className='block p-4 has-background-white-bis'>
                        <h4 className='subtitle'>Version</h4>
                        <ReactJson src={this.props.diagnostics.meta} />
                    </div>
                    : ''}

                {(this.props.diagnostics.schemaVersion > 1)
                    ?
                    <div className='block p-4 has-background-white-bis'>
                        <h4 className='subtitle'>URL</h4>
                        <ReactJson src={this.props.diagnostics.url} />
                    </div>
                    : ''}
            </div>
        );
    }
}