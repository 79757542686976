/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback, useEffect, useReducer, useRef, useState } from 'react';
import api from '../../../lib/Api';
import ConfigurationSetModel from '../../../lib/models/ConfigurationSet';
import ConfigurationValue from '../../../lib/models/ConfigurationValue';
import { v4 as uuidv4 } from 'uuid';
import { faEllipsisH, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Menu, MenuButton, MenuList, MenuItem } from '@reach/menu-button';
import { toast } from 'react-toastify';
import { AlertDialog, AlertDialogDescription, AlertDialogLabel } from '@reach/alert-dialog';
import "@reach/dialog/styles.css";
import authentication from '../../../lib/AuthenticationManager';
import { animated, useSpring } from 'react-spring';

interface Props extends ConfigurationSetModel {
    handleDelete(configurationSet: ConfigurationSetModel): void;
}

export const ConfigurationSet: React.FC<Props> = (props) => {
    const [details, setDetails] = useState<ConfigurationValue[]>([]);
    const [deletingDetails, setDeletingDetails] = useState<ConfigurationValue[]>([]);
    const [uneditedDetails, setUneditedDetails] = useState<ConfigurationValue[]>([]);
    const [editing, setEditing] = useState(false);
    const [adding, setAdding] = useState(false);
    const [isDefault, setIsDefault] = useState(props.isDefault);
    const [showDialog, setShowDialog] = useState(false);
    const open = () => setShowDialog(true);
    const close = () => setShowDialog(false);

    const reducer = (state: { keys: ConfigurationValue['id'][], values: Record<ConfigurationValue['id'], ConfigurationValue> }, action: { type: string, payload?: any }) => {
        switch (action.type) {
            case 'add': {
                const id = uuidv4();
                return { keys: [...state.keys, id], values: { ...state.values, [id]: new ConfigurationValue({ id, configurationSetId: props.id }) } };
            }
            case 'update': {
                const { id, ...payload } = action.payload;
                const updatedValue = { ...state.values[id], ...payload };
                return { ...state, values: { ...state.values, [id]: updatedValue } }
            }
            case 'delete':
                const newKeys = state.keys.filter(k => k !== action.payload.id);
                delete state.values[action.payload.id];
                return { ...state, keys: newKeys };
            case 'clean':
                return { keys: [], values: {} };
        };

        return state;
    };

    const [addingConfigurationValuesState, dispatch] = useReducer(reducer, { keys: [], values: {} });

    const cancelRef = useRef(null);

    const defaultTagStyles = useSpring({opacity: isDefault ? 1 : 0});

    useEffect(() => {
        api.configuration.listValues(props.id).then((values) => { setDetails(values); });
    }, [props.id]);

    useEffect(() => {
        if (editing && uneditedDetails.length === 0) {
            setUneditedDetails(details);
        } else if (!editing && uneditedDetails.length > 0) {
            setUneditedDetails([]);
        }
    }, [details, editing, uneditedDetails]);

    useEffect(() => {
        if (adding) {
            dispatch({ type: 'clean' });
            dispatch({ type: 'add' });
        }
        else
            dispatch({ type: 'clean' });
    }, [adding, props.id]);

    const loadConfigurationSetDetails = useCallback(() => {
        api.configuration.listValues(props.id).then((values) => {
            setDetails(values);
        });
    }, [props.id]);

    const renderAddValuePanel = useCallback(() => {
        const update = () => {
            const promises: Promise<unknown>[] = [];
            addingConfigurationValuesState.keys.forEach(id => promises.push(api.configuration.addValue(addingConfigurationValuesState.values[id])));
            Promise.all(promises).then(loadConfigurationSetDetails);
            setAdding(false);
        };

        if (adding) {
            return (
                <>
                    {/* {addingConfigurationValues.map(cv => <ConfigurationValuePanel configurationValue={cv} onUpdate={update} />)} */}
                    {/* {addingConfigurationValues.map((cv, i, a) => */}
                    {addingConfigurationValuesState.keys.map((id, i, a) =>
                        <div className="dialog" key={id}>
                            <div className='block'>
                                <div className='columns is-vcentered'>
                                    <div className='column is-6'>
                                        <div className='field'>
                                            <label className='label'>Name</label>
                                            <input className='input' onChange={(ev) => dispatch({ type: 'update', payload: { id, name: ev.target.value } })} />
                                        </div>
                                    </div>
                                    <div className='column is-5'>
                                        <div className='field'>
                                            <label className='label'>Value</label>
                                            <input className='input' onChange={(ev) => dispatch({ type: 'update', payload: { id, value: ev.target.value } })} />
                                        </div>
                                    </div>
                                    <div className='column is-1 mt-5'>
                                        <button disabled={i === 0 && a.length === 1} title='remove' className='delete' onClick={() => (dispatch({ type: 'delete', payload: { id } }))}></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    <button className='button no-button has-text-primary p-0' title='add new' onClick={() => (dispatch({ type: 'add' }))}><FontAwesomeIcon icon={faPlusCircle} /></button>
                    <div className='buttons is-right'>
                        <button className='button' onClick={() => setAdding(false)}>Cancel</button>
                        <button className='button is-primary' onClick={() => update()}>Confirm</button>
                    </div>
                </>
            );

        }
        return null;
    }, [adding, addingConfigurationValuesState.keys, addingConfigurationValuesState.values, loadConfigurationSetDetails]);

    const handleEdit = useCallback(() => {
        setEditing(false);
        const promises: Promise<any>[] = [];
        deletingDetails.forEach(detail => promises.push(api.configuration.deleteValue(detail)));
        Promise.all(promises)
            .then(() => {
                toast('Edit successful!', { type: 'dark' });
            })
            .catch((reason) => alert(reason));
    }, [deletingDetails]);

    const handleEditCancel = useCallback(() => {
        setEditing(false);
        setDetails(uneditedDetails);
    }, [uneditedDetails]);

    const setDefault = useCallback((val) => {
        setIsDefault(val);
        api.configuration.setDefault(props.id, val);
    }, [props.id, setIsDefault]);

    const renderSetDetails = () => {
        return (
            <div className='block'>
                <h5 className='block'>Values ({details.length})</h5>

                {details.length > 0 ?

                    <table className='table is-striped is-fullwidth'>
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Value</th>
                                {editing && <th>&nbsp;</th>}
                            </tr>
                        </thead>
                        <tbody>
                            {details.map((val, i) =>
                                <tr key={val.id}>
                                    {editing ?
                                        <>
                                            <td>{val.name}</td>
                                            <td>{val.value}</td>
                                            <td><button className='delete is-small' title='Remove' onClick={() => { setDeletingDetails([...deletingDetails, details.find(d => d.id === val.id)!]); setDetails(details.filter(d => d.id !== val.id)); }}></button></td>
                                        </>
                                        :
                                        <>
                                            <td>{val.name}</td>
                                            <td>{val.value}</td>
                                        </>
                                    }
                                </tr>)}
                        </tbody>
                    </table>
                    :
                    <>
                        {!adding && <p>No values found</p>}
                    </>
                }

                {authentication.isManager && !adding && !editing &&
                    <div className='has-text-right'><button className='button is-primary' onClick={() => setAdding(true)}>Add</button></div>
                }

                {!adding && editing &&
                    <div className='buttons is-right'>
                        <button className='button' onClick={() => handleEditCancel()}>Cancel</button>
                        <button className='button is-primary' onClick={() => handleEdit()}>Confirm</button>
                    </div>
                }

            </div>
        );
    };

    return (
        <div className='column is-12'>
            <div className='card' key={props.id}>
                <header className='card-header'>
                    <h4 className='card-header-title is-justify-content-space-between'>
                        <div className='is-flex is-align-items-center'>
                            <span>{props.name}</span>
                            <animated.div style={defaultTagStyles} className='tag is-info ml-5'>default</animated.div>
                        </div>
                        {authentication.isManager &&
                            <Menu>
                                <MenuButton className={`dropdown-trigger is-pulled-right no-button is-size-5`}><FontAwesomeIcon className='has-text-grey-dark' icon={faEllipsisH} /></MenuButton>
                                <MenuList className='dropdown-content'>
                                    <MenuItem className='dropdown-item' onSelect={() => setEditing(true)}>Edit</MenuItem>
                                    {!isDefault &&
                                        <MenuItem className='dropdown-item' onSelect={() => setDefault(true)}>Set Default</MenuItem>
                                    }
                                    {isDefault &&
                                        <MenuItem className='dropdown-item' onSelect={() => setDefault(false)}>Remove Default</MenuItem>
                                    }
                                    <MenuItem className='dropdown-item' onSelect={open}>Delete</MenuItem>
                                </MenuList>
                            </Menu>
                        }
                    </h4>
                </header>
                <div className='card-content'>
                    <div>
                        {renderSetDetails()}
                        {renderAddValuePanel()}
                    </div>
                </div>
            </div>
            {showDialog &&
                <AlertDialog leastDestructiveRef={cancelRef}>
                    <AlertDialogLabel className='title'>Delete {props.name}</AlertDialogLabel>
                    <AlertDialogDescription>Are you sure you want to delete {props.name}? This action cannot be undone.</AlertDialogDescription>
                    <div className='buttons is-right'>
                        <button className='button' onClick={close} ref={cancelRef}>Cancel</button>
                        <button className='button is-primary' onClick={() => { close(); props.handleDelete(props); }}>Confirm</button>
                    </div>
                </AlertDialog>
            }
        </div >
    )
}

export default ConfigurationSet;