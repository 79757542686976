import { RouteComponentProps } from '@reach/router';
import React from 'react';
import api from '../lib/Api';
import authentication from '../lib/AuthenticationManager';
import './Packages.css';

interface Props extends RouteComponentProps { }

interface State {
    packages: any[];
    url: string;
}

function formatBytes(bytes: number, decimals = 2) {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

export default class Packages extends React.Component<Props, State> {
    private elem?: React.RefObject<HTMLTextAreaElement>;

    public constructor(props: Props) {
        super(props);

        this.state = { packages: [], url: '' };

        api.packages.list().then(packages => this.setState({ packages }));
    }

    public componentDidUpdate() {
        if (this.elem && this.elem.current) {
            this.elem!.current!.value = this.state.url;
        }
    }

    public render() {
        return <div id="Packages" className='section container'>
            <h1 className='title'>Packages</h1>
            <h1 className='subtitle'>Generate links for Causelink Releases</h1>

            <div className="columns">
                <div className="packages">
                    {this.state.packages.filter(s3 => !s3.key.startsWith('archive')).map((s3) => {
                        return <div key={s3.key} className='column'>
                            <strong>{s3.key}</strong> · {formatBytes(s3.size)} ·  last modified {s3.lastModified}

                            {authentication.isManager
                                ? <div className='buttons'>
                                    <button className='button is-small' onClick={() => api.packages.url(s3.key, 1).then(url => this.setState({ url }))}>1 day</button>
                                    <button className='button is-small' onClick={() => api.packages.url(s3.key, 3).then(url => this.setState({ url }))}>3 days</button>
                                    <button className='button is-small' onClick={() => api.packages.url(s3.key, 7).then(url => this.setState({ url }))}>7 days</button>
                                </div>
                                : ''}
                        </div>;
                    })}
                </div>

                {this.state.url ? <div className='column box'>{this.state.url}</div> : ''}
            </div>
        </div>;
    }
}