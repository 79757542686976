import React from 'react';
import api from '../lib/Api';
import authentication from '../lib/AuthenticationManager';
import ApplicationUser from '../lib/models/ApplicationUser';
import Known from '../lib/Known';
import './Users.css';
import { RouteComponentProps } from '@reach/router';

interface Props extends RouteComponentProps { }
interface State { 
    users: ApplicationUser[];
}

export default class Users extends React.Component<Props, State> {
    public constructor(props: Props) {
        super(props);

        this.state = { users: [] };
        api.users.list().then((users) => this.setState({ users }));
    }

    public render() {
        return (
            <div id="Users" className='section container'>
                <h1 className='title'>Users</h1>

                <table className='table is-striped'>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Role</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.users.map((user) => {
                            return <tr key={user.id}>
                                <td>{user.displayName}</td>
                                <td>{authentication.isAdmin
                                    ? <select className='select' onChange={this.onPromoteUser.bind(this, user.id)} defaultValue={user.roleId}>
                                        <option value={Known.Roles.AnalystId}>Analyst</option>
                                        <option value={Known.Roles.ManagerId}>Manager</option>
                                        <option value={Known.Roles.AdminId}>Admin</option>                                                                        
                                    </select>
                                    : <span>{user.roleName}</span>}</td>
                            </tr>;
                        })}
                    </tbody>
                </table>
            </div>);
    }

    private onPromoteUser(userId: string, ev: React.ChangeEvent<HTMLSelectElement>) {
        api.users.promote(userId, ev.target.value);
    }
}