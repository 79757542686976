import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import api from '../../../lib/Api';
import ConfigurationSetModel from '../../../lib/models/ConfigurationSet';
import ConfigurationValue from '../../../lib/models/ConfigurationValue';
import ConfigurationSetPanel from './ConfigurationSetPanel';
import ConfigurationSet from './ConfigurationSet';
import { RouteComponentProps } from '@reach/router';
import authentication from '../../../lib/AuthenticationManager';

class ViewState {
    showAddSetPanel: boolean = false;
    showEditSetPanel: boolean = false;
    showSetDetails: boolean = false;
    showAddValuePanel: boolean = false;
    showConfirmDialog: boolean = false;
}

interface Confirmation {
    label: string;
    resolve: () => void;
    reject: () => void;
}

interface Props extends RouteComponentProps { }
interface State {
    sets: ConfigurationSetModel[];
    details: Map<string, ConfigurationValue[]>;
    targetConfigurationSet: ConfigurationSetModel | undefined;
    viewState: ViewState;
    confirm: Confirmation | undefined;
}

export default class ConfigurationSets extends React.Component<Props, State> {
    public constructor(props: Props) {
        super(props);

        this.state = {
            sets: [],
            details: new Map<string, ConfigurationValue[]>(),
            viewState: new ViewState(),
            targetConfigurationSet: undefined,
            confirm: undefined
        };

        api.configuration.listSets().then(sets => {
            this.setState({ sets });

            // sets.forEach((cs) => this.loadConfigurationSetDetails(cs.id));
        });
    }

    public render() {
        return (
            <div className='section container'>
                <h1 className='title'>Configuration Sets</h1>
                <h2 className='subtitle'>Manage configurations for instances</h2>

                {this.state.sets.length === 0 && !this.state.viewState.showAddSetPanel &&
                    <p className='block'>No configuration sets found.</p>
                }

                <div className='columns is-multiline block'>
                    {this.state.sets.map(cs =>
                        <ConfigurationSet {...cs} handleDelete={this.deleteConfigurationSet} />
                    )}
                </div>

                {!this.state.viewState.showAddSetPanel && authentication.isManager &&
                    <div className='buttons is-right'>
                        <button className='button is-primary' onClick={this.showAddSetPanel.bind(this)}>Add Configuration Set</button>
                    </div>
                }

                {this.state.viewState.showConfirmDialog ? this.renderConfirmDialog() : ''}

                {this.state.viewState.showAddSetPanel ? this.renderAddSetPanel(true) : ''}
                {/* {this.state.viewState.showEditSetPanel ? this.renderAddSetPanel(false) : ''} */}

                {/* {this.state.viewState.showAddValuePanel ? this.renderAddValuePanel() : ''} */}

                {/* {this.state.sets.map(cs =>
                    <div className='card' key={cs.id}>
                        <header className='card-header'>
                            <h4 className='card-header-title'>{cs.name}</h4>
                            <button className="card-header-icon" aria-label='more options'>
                                <span className='icon'>
                                    <i className='fas fa-angle-down' aria-hidden='true'></i>
                                </span>
                            </button>
                        </header>
                        <div className='card-content'>
                            <div className='content'>
                                {this.renderSetDetails(cs)}
                            </div>
                        </div>
                        <footer className='card-footer'>
                            <button className='card-footer-item' onClick={this.showEditSetPanel.bind(this, cs)}>Edit</button>
                            <button className='card-footer-item' onClick={this.deleteConfigurationSet.bind(this, cs)}>Delete</button>
                        </footer>

                    </div>
                )} */}
            </div>)
    }



    private renderAddSetPanel(isAdd: boolean) {
        const configurationSet = isAdd
            ? new ConfigurationSetModel({ id: uuidv4() })
            : this.state.targetConfigurationSet!;

        const hide = () => {
            const viewState = { ...this.state.viewState };
            viewState.showAddSetPanel = false;
            viewState.showEditSetPanel = false;

            this.setState({ viewState });
        };

        const update = () => {
            hide();

            if (isAdd) {
                api.configuration.addSet(configurationSet).then(() =>
                    api.configuration.listSets().then(sets => this.setState({ sets })));
            } else {
                api.configuration.update(configurationSet);
            }
        };

        return <ConfigurationSetPanel configurationSet={configurationSet} isAdd={isAdd} onUpdate={update} onHide={hide} />;
    }

    private showAddSetPanel() {
        const viewState = { ...this.state.viewState };
        viewState.showAddSetPanel = true;

        this.setState({ viewState });
    }

    private showEditSetPanel(cs: ConfigurationSetModel) {
        const viewState = { ...this.state.viewState };
        viewState.showEditSetPanel = true;

        this.setState({ viewState, targetConfigurationSet: cs });
    }



    // private deleteConfigurationValue(cv: ConfigurationValue) {
    //     this.confirm(`Delete Configuration ${cv.name}`).then(() =>
    //         api.configuration.deleteValue(cv).then(() => this.loadConfigurationSetDetails(cv.configurationSetId)));
    // }

    private deleteConfigurationSet = (cs: ConfigurationSetModel) => {
        api.configuration.deleteSet(cs).then(() =>
            api.configuration.listSets().then(sets => this.setState({ sets })));
    }

    private confirm(label: string): Promise<void> {
        return new Promise((resolve, reject) => {
            const viewState = { ...this.state.viewState };
            viewState.showConfirmDialog = true;

            this.setState({ viewState, confirm: { label, resolve, reject } });
        });
    }

    private renderConfirmDialog() {
        const confirm = () => {
            const viewState = { ...this.state.viewState };
            viewState.showConfirmDialog = false;

            this.state.confirm!.resolve();
            this.setState({ viewState, confirm: undefined });
        };

        const cancel = () => {
            const viewState = { ...this.state.viewState };
            viewState.showConfirmDialog = false;

            this.state.confirm!.reject();
            this.setState({ viewState, confirm: undefined });
        };

        return <div className="confirm dialog">
            <h2>Confirm {this.state.confirm?.label}</h2>

            <button onClick={confirm}>Confirm</button>
            <button onClick={cancel}>Cancel</button>
        </div>;
    }
}