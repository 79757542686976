import React from 'react';
import Installation from '../../../../lib/models/Installation';
import { AddCauselinkInstanceCommand, RemoveCauselinkInstanceCommand, describeCommandType, InstallDatabaseCommand, RebuildIndexCommand, UpdateConfigurationCommand, RemoteCommand, RemoteCommandType, RestartCommand, MigrateCommand, UpdateDatabaseCommand, RemoveDatabaseCommand, AddToUptimeRobot, RemoveFromUptimeRobot } from '../../../../lib/models/RemoteCommand';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import arrayMove from 'array-move';
import './CommandPanel.css';
import Select from 'react-select';
import { SelectInput } from '../../../../lib/SelectInput';
import api from '../../../../lib/Api';
import ConfigurationSet from '../../../../lib/models/ConfigurationSet';
import { Spring, animated } from 'react-spring';
import { theme } from '../../../../lib/causelinkSelectTheme';

type CommandUpdateHandler = (commands: RemoteCommand[]) => void;

interface Props {
    installation: Installation;
    commands: RemoteCommand[];
    onCommandUpdate?: CommandUpdateHandler;
}

interface StagedCommand {
    type: RemoteCommandType | undefined;
    migrateStep: string;
    configurationSetIds: string[];
}

interface State {
    configurationSets: ConfigurationSet[];
    showAdd: boolean;
    staged: StagedCommand;
}

export default class CommandPanel extends React.Component<Props, State> {
    public constructor(props: Props) {
        super(props);

        this.state = {
            showAdd: false,
            configurationSets: [],
            staged: {
                type: undefined,
                migrateStep: '',
                configurationSetIds: []
            }
        };

        api.configuration.listSets().then((configurationSets) =>
            this.setState({ configurationSets }));
    }

    public render() {
        if (this.state.showAdd) {
            return this.renderAdd();
        }

        return this.renderDefault();
    }

    private renderAdd() {
        const updateCommandType = (nct: number) => {
            const staged = { ...this.state.staged };
            staged.type = nct;

            this.setState({ staged });
        };

        const updateConfigurationSetIds = (ids: string[]) => {
            const staged = { ...this.state.staged };
            staged.configurationSetIds = ids;

            this.setState({ staged });
        }

        const updateMigrateStep = (ms: string) => {
            const staged = { ...this.state.staged };
            staged.migrateStep = ms;

            this.setState({ staged });
        };

        const hide = () => this.setState({ showAdd: false });

        const onAdd = () => {
            if (this.state.staged.type === undefined) {
                hide();
                return;
            }

            const commands = [...this.props.commands];

            switch (this.state.staged.type) {
                case RemoteCommandType.AddCauselinkInstance:
                    commands.push(new AddCauselinkInstanceCommand(this.props.installation.id));
                    break;
                case RemoteCommandType.RemoveCauselinkInstance:
                    commands.push(new RemoveCauselinkInstanceCommand(this.props.installation.id));
                    break;
                case RemoteCommandType.InstallDatabase:
                    commands.push(new InstallDatabaseCommand(this.props.installation.id));
                    break;
                case RemoteCommandType.UpdateDatabase:
                    commands.push(new UpdateDatabaseCommand(this.props.installation.id));
                    break;
                case RemoteCommandType.RemoveDatabase:
                    commands.push(new RemoveDatabaseCommand(this.props.installation.id));
                    break;
                case RemoteCommandType.RebuildIndex:
                    commands.push(new RebuildIndexCommand(this.props.installation.id));
                    break;
                case RemoteCommandType.UpdateConfiguration:
                    const command = new UpdateConfigurationCommand(this.props.installation.id);
                    command.configurationSetIds = this.state.staged.configurationSetIds;
                    commands.push(command);
                    break;
                case RemoteCommandType.Restart:
                    commands.push(new RestartCommand(this.props.installation.id));
                    break;
                case RemoteCommandType.Migrate:
                    const mc = new MigrateCommand(this.props.installation.id);
                    mc.migrateStep = this.state.staged.migrateStep;
                    commands.push(mc);
                    break;
                case RemoteCommandType.AddToUptimeRobot:
                    commands.push(new AddToUptimeRobot(this.props.installation.id));
                    break;
                case RemoteCommandType.RemoveFromUptimeRobot:
                    commands.push(new RemoveFromUptimeRobot(this.props.installation.id));
                    break;
            }

            if (this.props.onCommandUpdate !== undefined) {
                this.props.onCommandUpdate(commands);
            }

            hide();
        };

        // build list of remote command types
        let rctypes: number[] = [];
        for (const val in RemoteCommandType) {
            const v = parseInt(val);
            if (!isNaN(v)) {
                rctypes.push(v);
            }
        }

        // build options of configuration sets
        const configurationSets = this.state.configurationSets.map((cs) => { return { value: cs.id, label: cs.name }; });

        return <div id="CommandPanel">
            <div className="add">
                <h2>Add Command</h2>

                <ul>
                    {this.props.commands.map((command) =>
                        <li key={command.id}>{command.toString()}</li>)}
                </ul>

                <form className='form'>
                    <div className='field'>
                        <label className='label' htmlFor='commandtype'>Command Type</label>
                        <Select
                            className='control'
                            theme={theme}
                            options={rctypes.map((rcType) => ({ value: rcType, label: describeCommandType(rcType) }))}
                            onChange={(option) => updateCommandType(option!.value)}
                        />
                    </div>

                    {this.state.staged.type === RemoteCommandType.Migrate
                        ? <div>migrate step <input onChange={(ev) => updateMigrateStep(ev.target.value)} /></div>
                        : ''}


                    {/* @ts-ignore */}
                    <Spring opacity={this.state.staged.type === RemoteCommandType.UpdateConfiguration ? 1 : 0} display={this.state.staged.type === RemoteCommandType.UpdateConfiguration  ? 'block' : 'none'} config={{}}>
                        {style =>
                            <animated.div style={style} className='field'>
                                <Select
                                    placeholder='Select Configuration Set(s)...'
                                    id='update-config-sets'
                                    options={configurationSets}
                                    isMulti={true}
                                    theme={theme}
                                    style={style}
                                    closeMenuOnSelect={false}
                                    noOptionsMessage={() => null}
                                    classNamePrefix='react-select'
                                    onChange={(selected) => updateConfigurationSetIds(selected.map((o) => o.value))} />
                            </animated.div>
                        }
                    </Spring>

                </form>

                <div className='buttons are-small'>
                    <button className='button is-primary' onClick={onAdd}>Confirm</button>
                    <button className='button' onClick={hide}>Cancel</button>
                </div>
            </div>
        </div>;
    }

    private renderDefault() {
        const SortableCommandItem = SortableElement(({ value }: { value: RemoteCommand }) => {
            let desc = value.toString();

            if (value.type === RemoteCommandType.UpdateConfiguration) {
                const ucc = value as UpdateConfigurationCommand;

                desc += ': ' + ucc.configurationSetIds
                    .map((id) => this.state.configurationSets.find((cs) => cs.id === id)!)
                    .map((cs) => cs.name)
                    .join(', ');
            }

            return (<li className='control mt-3'>
                <div className='tags has-addons are-medium'>
                    <div className='tag'>{desc}</div>
                    <button className='tag is-delete no-button is-primary' title='Remove' onClick={() => this.onRemove(value)} />
                </div>
            </li>);
        });

        const SortableCommandList = SortableContainer(({ items }: { items: RemoteCommand[] }) => {
            return (
                <ul className='field'>
                    {items.map((value, index) => (
                        <SortableCommandItem key={value.id} index={index} value={value} />
                    ))}
                </ul>
            );
        });

        return <div id="CommandPanel">
            <h2>Send Command(s) to {this.props.installation.name}</h2>

            <SortableCommandList
                distance={5}
                helperClass='sortable-helper'
                items={this.props.commands}
                onSortEnd={this.onSortEnd.bind(this)} />

            <div><button className='button is-small is-primary' onClick={() => this.setState({ showAdd: true })}>Add command</button></div>
        </div>;
    }

    private onSortEnd(sortInfo: any) {
        const commands = arrayMove(this.props.commands, sortInfo.oldIndex, sortInfo.newIndex);

        if (this.props.onCommandUpdate !== undefined) {
            this.props.onCommandUpdate(commands);
        }
    }

    private onRemove(trash: RemoteCommand) {
        const commands = this.props.commands.filter((c) => c.id !== trash.id);

        if (this.props.onCommandUpdate !== undefined) {
            this.props.onCommandUpdate(commands);
        }
    }
}