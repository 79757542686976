import React from 'react';
import Installation from '../../../lib/models/Installation';
import ModelInput from '../../../lib/ModelInput';
import CommandPanel from './command/CommandPanel';
import { AddCauselinkInstanceCommand, AddToUptimeRobot, InstallDatabaseCommand, RebuildIndexCommand, RemoteCommand, UpdateConfigurationCommand } from '../../../lib/models/RemoteCommand';
import './InstallationPanel.css';
import { Spring } from '@react-spring/core';
import { animated } from '@react-spring/web';
import Segment from '../../../lib/models/Segment';
import api from '../../../lib/Api';

interface Props {
    isAdd: boolean;
    installation: Installation;
    segment: Segment;

    onHide: () => void;
    onUpdate: (installation: Installation, commands?: RemoteCommand[], connectionString?: string) => void;
}

interface State extends Installation {
    commands: RemoteCommand[];
    confirmConnectionStringChange: boolean;
}

export default class InstallationPanel extends React.Component<Props, State> {
    public async componentDidMount() {
        const configurationSets = await api.configuration.listSets();

        const commands: RemoteCommand[] = [];

        if (this.props.isAdd) {
            commands.push(new AddCauselinkInstanceCommand(this.props.installation.id));
            commands.push(new InstallDatabaseCommand(this.props.installation.id));
            const updateConfigurationCommand = new UpdateConfigurationCommand(this.props.installation.id);
            commands.push(updateConfigurationCommand);
            commands.push(new RebuildIndexCommand(this.props.installation.id));
            commands.push(new AddToUptimeRobot(this.props.installation.id));

            configurationSets.forEach(cs => {
                if (cs.isDefault)
                    updateConfigurationCommand.configurationSetIds.push(cs.id);
            });
            this.setState({ commands });
            this.forceUpdate();
        }

    }

    public constructor(props: Props) {
        super(props);

        this.state = { commands: [], confirmConnectionStringChange: false, ...this.props.installation };
    }

    public render() {
        return <div id="InstallationPanel">
            <div className='block'>
                <ModelInput label='Name' src={this.state} field='name' isReadOnly={!this.props.isAdd} onUpdate={() => this.forceUpdate()} />
                {this.props.isAdd &&
                    <div className='field'>
                        <label className='label'>DNS</label>
                        <div className='field has-addons'>
                            <div className='control'>
                                <input type='text' className='input' onChange={(ev) => this.setState({ dns: ev.target.value })} />
                            </div>
                            <div className='control'><div className='button is-static'>.{this.props.segment.domain}</div></div>
                        </div>
                    </div>
                }

                <ModelInput label='Version Match' src={this.state} field='versionMatch' onUpdate={() => this.forceUpdate()} />

                {!this.props.isAdd &&
                    <div className='field'>
                        <label className='label'>Connection String</label>
                        <div className='control'>
                            <input type='text' className='input' onChange={(ev) => this.setState({ connectionString: ev.target.value })} />
                        </div>
                    </div>
                }
            </div>

            {this.props.isAdd
                ? <CommandPanel installation={this.state} commands={this.state.commands} onCommandUpdate={(commands) => this.setState({ commands })} />
                : ''
            }

            {/* @ts-ignore */}
            <Spring opacity={this.state.connectionString ? 1 : 0} display={this.state.connectionString ? 1 : 0}>
                {style =>
                    <animated.div style={style}>
                        <label className='checkbox' htmlFor='confirm-change'>
                            <input id='confirm-change' type='checkbox' className='mr-2' checked={this.state.confirmConnectionStringChange} onChange={(ev) => this.setState({ confirmConnectionStringChange: ev.target.checked })} />
                            I acknowledge setting the connection string is a dangerous procedure and should not be taken lightly
                        </label>
                    </animated.div>
                }
            </Spring>

            <div className='buttons is-right'>
                <button className='button' onClick={() => this.props.onHide()}>Cancel</button>
                <button className='button is-primary' disabled={!!this.state.connectionString && !this.state.confirmConnectionStringChange} onClick={() => { (this.state.connectionString && this.state.confirmConnectionStringChange) && (this.props.installation.connectionString = this.state.connectionString); this.props.onUpdate(this.state, this.state.commands) }}>{this.props.isAdd ? 'Add' : 'Update'}</button>
            </div>
        </div >
    }
}