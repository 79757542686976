import React from 'react';

type OnConfirm = any;
type OnCancel = () => void;

interface Props {
    action: string;
    onConfirm?: OnConfirm;
    onCancel?: OnCancel;
}

interface State {
    userInput: string;
    waiting: boolean;
}

export default class ConfirmPanel extends React.Component<Props, State> {
    public constructor(props: Props) {
        super(props);

        this.state = { userInput: '', waiting: false, };
    }

    public input = React.createRef<any>();

    public componentDidMount() {
        this.input.current!.focus();
    }

    public render() {
        const primaryButtonClasses = ['button', 'is-primary'];
        if (this.state.waiting)
            primaryButtonClasses.push('is-loading');

        return <form className="confirm dialog section container">
            <div className='block'>
                <h2 className='title'>Confirm {this.props.action}</h2>

                <p className='block'>The operation you are about to perform has real-world impacts. Type '<strong>confirm</strong>' to continue:</p>

                <input ref={this.input} placeholder='Enter confirmation text here' className='input' type='text' onChange={ev => this.setState({ userInput: ev.target.value })} />
            </div>
            {this.props.children}
            <div className='buttons'>
                <button type='submit' className={primaryButtonClasses.join(' ')} disabled={this.state.userInput !== 'confirm'} onClick={(ev) => this.confirm(ev)}>Confirm</button>
                <button className='button' onClick={() => this.props.onCancel?.call(this)}>Cancel</button>
            </div>
        </form>;
    }

    private confirm = (ev: React.MouseEvent) => {
        ev.preventDefault();

        if (this.state.userInput !== 'confirm') {
            alert('not confirmed, no action');
            return;
        }

        this.setState({ waiting: true });
        this.props.onConfirm?.call(this);
    }
}