import React from 'react';
import ReactJson from "react-json-view";
import DiagnosticSchema, { PersistentDiagnosticEntry, DiagnosticEventType } from '../../lib/DiagnosticSchema';
import DiagnosticDate from '../../lib/DiagnosticDate';
import moment from 'moment';
import CurrentSession from '../../lib/CurrentSession';
import { RouteComponentProps } from '@reach/router';

import './ReplayPanel.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

interface Props extends RouteComponentProps {
    diagnostics: DiagnosticSchema;
}

interface State {
    byDate: Map<string, PersistentDiagnosticEntry[]>;
    current: string | undefined;
}

export default class ReplayPanel extends React.Component<Props, State> {
    private isAlt: boolean = false;

    public constructor(props: Props) {
        super(props);

        const byDate = new Map<string, PersistentDiagnosticEntry[]>();

        props.diagnostics.data.persistent.forEach((entry) => {
            const key = moment(entry.at).utcOffset(CurrentSession.offset).format('YYYY-MM-DD');

            if (!byDate.has(key)) {
                byDate.set(key, []);
            }

            byDate.get(key)!.push(entry);
        });

        this.state = { byDate: byDate, current: undefined };
    }

    public render() {
        return (
            <div id="ReplayPanel" className='section container'>
                <h2 className='title is-flex is-align-items-center'>
                    {this.state.current &&
                        <button title='Back' className='button no-button back-button is-size-4 mr-4' onClick={() => this.setState({ current: undefined })}><FontAwesomeIcon icon={faArrowLeft} /></button>
                    }
                    <span>Replay Log</span>
                </h2>
                {this.state.current &&
                    <div className='subtitle'>
                        {this.state.current}
                    </div>
                }
                {(this.state.current === undefined) ?
                    this.renderChooseCurrent()
                    :
                    this.state.byDate.get(this.state.current)!.map(this.renderEntry.bind(this)) 
                }
            </div>
        );
    }

    private renderChooseCurrent() {
        return (
            <>
                <h2 className='subtitle'>Choose a date</h2>
                <div className='buttons are-large'>
                    {Array.from(this.state.byDate.keys()).map((key) =>
                        <button className='button is-outline is-primary is-rounded' onClick={() => this.setState({ current: key })}>
                            {key}
                        </button>)}
                </div>
            </>
        );
    }

    private renderEntry(entry: PersistentDiagnosticEntry) {
        if (entry.is(DiagnosticEventType.Error)) {
            return this.renderErrorEntry(entry);
        }

        if (entry.is(DiagnosticEventType.Startup)) {
            return this.renderStartupEntry(entry);
        }

        if (entry.is(DiagnosticEventType.Click)) {
            return this.renderClickEntry(entry);
        }

        if (entry.is(DiagnosticEventType.Http)) {
            return this.renderHttpEntry(entry);
        }

        if (entry.is(DiagnosticEventType.Navigate)) {
            return this.renderNavigateEntry(entry);
        }

        return '';
    }

    private renderStartupEntry(entry: PersistentDiagnosticEntry) {
        return (
            <div className={this.getClassName('startup')}>
                <div className="at"><DiagnosticDate date={moment(entry.at)} /></div>
                startup
            </div>
        )
    }

    private renderErrorEntry(entry: PersistentDiagnosticEntry) {
        return (
            <div className={this.getClassName('error')}>
                <div className="at"><DiagnosticDate date={moment(entry.at)} /></div>
                <div className="details">
                    <ReactJson src={entry.details} />
                </div>
            </div>
        )
    }

    private renderNavigateEntry(entry: PersistentDiagnosticEntry) {
        this.isAlt = !this.isAlt;

        return (
            <div className={this.getClassName('navigate')}>
                <div className="at"><DiagnosticDate date={moment(entry.at)} /></div>
                <div className="details">
                    {(entry.details.fromState?.name !== '')
                        ? <span>{entry.details.fromState.name} ={'>'} </span>
                        : ''}
                    {entry.details.toState.name}
                </div>
            </div>
        )
    }

    private renderHttpEntry(entry: PersistentDiagnosticEntry) {
        return (
            <div className={this.getClassName('http')}>
                <div className="at"><DiagnosticDate date={moment(entry.at)} /></div>
                <div className="details">
                    {entry.is(DiagnosticEventType.HttpRequest)
                        ? `> ${entry.details.method} ${entry.details.url}`
                        : `< ${entry.details.method} ${entry.details.url} ${entry.details.status} ${entry.details.statusText}`}
                </div>
            </div>
        )
    }

    private renderClickEntry(entry: PersistentDiagnosticEntry) {
        return (
            <div className={this.getClassName('click')}>
                <div className="at"><DiagnosticDate date={moment(entry.at)} /></div>
                <div className="details">
                    <div>&lt;{entry.details.name.toLowerCase()} {entry.details.attributes.map((a: any) => `${a[0]}="${a[1]}" `)}&gt;</div>
                    {(entry.details.context != null)
                        ?
                        <div>
                            <div>isFiveWhysLayout: {entry.details.context.isFiveWhysLayout}</div>
                            <div>hasChart: {entry.details.context.hasChart}</div>
                            <div>hasTimeline: {entry.details.context.hasTimeline}</div>
                        </div>
                        : ''}
                </div>
            </div>
        )
    }

    private getClassName(key: string): string {
        return `${key} entry ${this.isAlt ? 'alt' : ''}`;
    }
}
