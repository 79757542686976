import React from 'react';
import DiagnosticInput from './panels/DiagnosticInput/DiagnosticInput';
import DiagnosticSchema from './lib/DiagnosticSchema';
import { DiagnosticDateType } from './lib/DiagnosticDate';
import MetaPanel from './panels/MetaPanel/MetaPanel';
import SessionPanel from './panels/SessionPanel/SessionPanel';
import ReplayPanel from './panels/ReplayPanel/ReplayPanel';
import CurrentSession from './lib/CurrentSession';
import StoryPanel from './panels/StoryPanel/StoryPanel';
import { RouteComponentProps, Router } from '@reach/router';
import './Diagnostics.scss';

interface Props extends RouteComponentProps {

}

interface State {
  diagnostics: DiagnosticSchema | undefined;
  panel: Panel;
  dateType: DiagnosticDateType;
}

enum Panel {
  Meta, Session, Replay, Story
}

export default class App extends React.Component<Props, State> {
  public constructor(props: Props) {
    super(props);

    CurrentSession.dateType = DiagnosticDateType.UTC;

    this.state = {
      panel: Panel.Meta,
      diagnostics: undefined,
      dateType: DiagnosticDateType.UTC
    };
  }

  public render() {
    return (
      <>
        {(this.state.diagnostics !== undefined)
          ? this.renderWithData()
          : <DiagnosticInput onDiagnosticsAvailable={this.onDiagnosticsAvailable.bind(this)} />}
      </>
    );
  }

  private renderWithData() {
    return (
      <div>
        <div className='time-zone-picker'><label><span>All dates in </span>
              <select onChange={this.onTZChange.bind(this)} value={CurrentSession.dateType}>
            <option value={DiagnosticDateType.UTC}>UTC</option>
            <option value={DiagnosticDateType.TheirTZ}>Their Time Zone</option>
            <option value={DiagnosticDateType.MyTZ}>My Time Zone</option>
          </select></label></div>
        <Router className='diagnostics-route p-4'>
          <DiagnosticInput path='/' onDiagnosticsAvailable={this.onDiagnosticsAvailable.bind(this)} />
          <MetaPanel path='meta' diagnostics={this.state.diagnostics!} />
          <SessionPanel path='session' diagnostics={this.state.diagnostics!} />
          <ReplayPanel path='replay' diagnostics={this.state.diagnostics!} />
          <StoryPanel path='story' diagnostics={this.state.diagnostics!} />
        </Router>
      </div>
    )
  }

  private onDiagnosticsAvailable(diagnostics: DiagnosticSchema) {
    this.setState({ diagnostics });

    CurrentSession.exportTimezone = diagnostics.at!.offset;
  }

  private onTZChange(event: React.ChangeEvent<HTMLSelectElement>) {
    CurrentSession.dateType = parseInt(event.target.value) as DiagnosticDateType;
    this.setState({ dateType: CurrentSession.dateType });
  }
}
